import * as React from "react";
import { Switch } from "react-router-dom";
import Root from "./page/root";
import SearchResults from "./page/search-results";
import { ApmRoute } from "@elastic/apm-rum-react";

export default function Router() {
    return (
      <div className="App">
        <Switch>
          <ApmRoute exact path="/" component={Root} />
          <ApmRoute path="/" component={SearchResults} />
        </Switch>
      </div>
    );
  }
  